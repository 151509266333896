import _createForOfIteratorHelper from "E:/DUMENGZHAO/Work/blog-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import AdaptiveLayout01 from '../components/AdaptiveLayout01.vue';
import CryptoJS from '../tools/CryptoJS';
import Swiper from 'swiper/swiper-bundle.esm.js';
import 'swiper/swiper-bundle.css';
import OsInfo from '../tools/OsInfo';
import SideBar01 from "../components/SideBar01";
import DateTool from "../tools/DateTool";
export default {
  setup: function setup() {
    var date = new Date();
    var ym = (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0');
    return {
      avatarUrl: '/FileService/images/a1/avatar-01.jpg',
      topSwiperData: [{
        url: require('../assets/swiper1/swiper-01.jpg')
      }, {
        url: require('../assets/swiper1/swiper-05.jpg')
      }, {
        url: require('../assets/swiper1/swiper-09.jpg')
      }, {
        url: require('../assets/swiper1/swiper-13.jpg')
      }],
      minSwiperData: [{
        url: '/FileService/images/20220401180358d0a9974bf545e4fd51z5vwe4aabnue52b5d532b6cbdbf63abbcfd.jpeg'
      }, {
        url: '/FileService/images/20220404151953dc70007fe85ubb6d58yr8ns46731tzx48188s2im63e8a3e77aa74.webp'
      }],
      myInfo: [{
        key: 'NICK',
        value: '小小多'
      }, {
        key: 'JOB',
        value: 'WEB全栈工程师'
      }, {
        key: 'ADD',
        value: '中国-湖北'
      }],
      cardData01: {
        badgeText: '心情随笔',
        title: '每个人都是一座孤岛',
        date: ym,
        id: 38
      },
      cardDataTwoCellOne: {
        badgeText: '心情随笔',
        title: '人生若只如初见',
        date: ym,
        id: 37,
        image: '/FileService/images/20220401173118d2d21b446365am77519udsj47f2757489bffx1i16390531c128e0.jpeg'
      },
      cardDataTwoCellThree: {
        badgeText: '心情随笔',
        title: '时光荏苒，岁月静好',
        date: ym,
        id: 39
      },
      cardDataTwoCellFour: {
        badgeText: '心情随笔',
        title: '浮生一世一场梦',
        date: ym,
        id: 40
      },
      cardKnowledgeDataOne: {
        badgeText: '知识笔记',
        title: 'G1垃圾收集器',
        date: ym,
        id: 36
      },
      cardKnowledgeDataTwo: {
        badgeText: '知识笔记',
        title: 'Java设计模式',
        date: ym,
        id: 37
      }
    };
  },
  data: function data() {
    return {
      params: null,
      isInitOneSwiper: false,
      //是否初始化了第一个轮播图
      imageTextData: [{
        id: 1,
        content: '',
        titleImg: 'https://dumengzhao.cn/static/images/hirofumi/00001.jpg'
      }]
    };
  },
  components: {
    AdaptiveLayout01: AdaptiveLayout01,
    SideBar01: SideBar01
  },
  methods: {
    showSwiperNavigationButton: function showSwiperNavigationButton(topSwiper) {
      topSwiper.navigation.$nextEl.removeClass('swiper-button-hidden');
      topSwiper.navigation.$prevEl.removeClass('swiper-button-hidden');
    },
    hideSwiperNavigationButton: function hideSwiperNavigationButton(topSwiper) {
      topSwiper.navigation.$nextEl.addClass('swiper-button-hidden');
      topSwiper.navigation.$prevEl.addClass('swiper-button-hidden');
    },
    modificationImageTextData: function modificationImageTextData(len) {
      var data = this.imageTextData;

      var _iterator = _createForOfIteratorHelper(data),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var d = _step.value;
          var content = d.content;
          content = content.substring(0, len) + '...';
          d.content = content;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    getImageTextData: function getImageTextData() {
      var that = this;
      var params = {};
      this.post('/api/article/upToDateDiaryList', params).then(function (res) {
        console.log(res);
        that.imageTextData = res.data;
        that.modificationImageTextData(99);
      }).catch(function (error) {
        console.log(error);
      });
    },
    initSwiperOne: function initSwiperOne() {
      var that = this;

      if (!OsInfo.info().isPc) {
        console.log("OsInfo ==> " + JSON.stringify(OsInfo) + ", not init swiper1");
        return;
      }

      console.log("init swiper1");
      that.isInitOneSwiper = true; //初始化轮播图

      var topSwiper = new Swiper('#swiper1', {
        loop: true,
        //无限循环
        speed: 1500,
        autoplay: {
          delay: 3000,
          //1秒切换一次
          disableOnInteraction: false,
          //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
          pauseOnMouseEnter: true //鼠标置于swiper时停止自动切换

        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }); //鼠标覆盖

      topSwiper.el.onmouseover = function () {
        if (OsInfo.info().isPc) {
          that.showSwiperNavigationButton(topSwiper);
        }
      }; //鼠标离开


      topSwiper.el.onmouseout = function () {
        if (OsInfo.info().isPc) {
          that.hideSwiperNavigationButton(topSwiper);
        }
      };
    },
    initSwiperTwo: function initSwiperTwo() {
      var mySwiper = new Swiper('#swiper2', {
        effect: 'fade',
        speed: 1500,
        loop: true,
        //无限循环
        autoplay: {
          delay: 5000,
          //1秒切换一次
          disableOnInteraction: false,
          //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
          pauseOnMouseEnter: true //鼠标置于swiper时停止自动切换

        }
      });
      console.log(mySwiper);
    },
    openModalWindow: function openModalWindow(elem, alpha, speed) {
      if (!alpha || isNaN(alpha) || Number(alpha) < 0) {
        alpha = 0;
      }

      if (!speed || isNaN(speed)) {
        speed = 500;
      }

      $('body').append('' + '<div id="dmz_modal_1" class="dmz-modal">' + '	<div class="modal-mask" style="background: rgba(0,0,0,' + alpha + ');" onclick="document.body.removeChild(document.getElementById(\'dmz_modal_1\'))"></div>' + '	<div class="modal-container">' + '		' + elem + '' + '	</div>' + '</div>');
      $('.modal-container').animate({
        opacity: 1,
        marginTop: '15px'
      }, speed);
    },
    openWxOrCode: function openWxOrCode() {
      var elem = '<img style="display: block;width: 250px;height: 250px;" src="/FileService/images/QrCode/mywx_002.png"  alt="wx"/>';
      this.openModalWindow(elem, 0.3, 500);
    },
    openNewPage: function openNewPage(href) {
      window.open(href);
    },
    toArticleDetails: function toArticleDetails(id, type) {
      var url = '/api/article/findById';

      if (type === 2) {
        url = '/api/knowledge/findById';
      }

      this.$router.push({
        name: "Detail",
        query: {
          key: CryptoJS.encryptByDES(JSON.stringify({
            id: id,
            url: url
          })),
          _t: DateTool.getNowTime()
        }
      });
    }
  },
  mounted: function mounted() {
    this.initSwiperOne();
    this.initSwiperTwo();
    var key = this.$route.query.key;
    console.log(key);

    try {
      if (key) {
        var query = CryptoJS.decryptByDES(key);
        console.log(JSON.parse(query));
        this.params = query;
      }
    } catch (e) {
      console.error(e);
    } //this.getImageTextData();

  }
}; //
// updateTime();